<template>
    <div class="container-fluid d-flex flex-wrap mt-5 main-wrapper" style="height: 90vh;">
        <!-- <div class="col-lg-3 col-12 pl-0 side-nav-bar-custom">
            <SideNav />
        </div> -->
        <div class="col-lg-12 col-12 p-0">
            <!-- Card header -->
            <div class="card-header border-0 d-flex flex-column">
                <div class="d-flex justify-content-between">
                    <h3 class="mb-0">
                        {{
                            $store.state.language === "en"
                                ? "Transactions"
                                : "ငွေစာရင်းများ"
                        }}
                    </h3>
                    <div>
                        <button
                            type="button"
                            class="btn btn-outline-primary"
                            :class="transactionType === 'all' ? 'active' : ''"
                            @click="fetchHistory('all')"
                        >
                            {{
                                $store.state.language === "en"
                                    ? "All"
                                    : "အားလုံး"
                            }}
                        </button>
                        <button
                            type="button"
                            class="btn btn-outline-primary"
                            :class="transactionType === 'in' ? 'active' : ''"
                            @click="fetchHistory('in')"
                        >
                            {{
                                $store.state.language === "en"
                                    ? "Deposit"
                                    : "ငွေသွင်း"
                            }}
                        </button>
                        <button
                            type="button"
                            class="btn btn-outline-primary"
                            :class="transactionType === 'out' ? 'active' : ''"
                            @click="fetchHistory('out')"
                        >
                            {{
                                $store.state.language === "en"
                                    ? "Withdraw"
                                    : "ငွေထုတ်"
                            }}
                        </button>
                    </div>
                </div>
                <div class="d-flex justify-content-end pt-lg-3 flex-wrap">
                        <div class="d-flex col-lg-6 col-12 justify-content-end p-0">
                            <div
                                class="col-lg-5 col-5 px-2 pt-2"
                            >
                                <v-select
                                    v-model="params.date"
                                    :reduce="(draw) => draw.data"
                                    label="label"
                                    :options="dateOptions"
                                ></v-select>
                            </div>
                            <div class="pt-1">
                                <button
                                    type="button"
                                    class="btn btn-primary ml-1"
                                    style="flex-shrink: 0"
                                    @click="fetchHistory"
                                >
                                    {{
                                        $store.state.language === "en"
                                            ? "Search"
                                            : "ရှာမည်"
                                    }}
                                </button>
                            </div>
                        </div>
                    </div>
            </div>
            <div class="table betslip-table pb-3">
                <div class="d-flex" v-if="isLoading">
                    <div class="mx-auto my-5">
                        <Loading
                            color="#ff0000"
                            size="24"
                            class="px-4 py-0"
                        />
                        <span>
                            Please Wait!
                        </span>
                    </div>
                </div>
                <DataTable v-if="!isLoading" :tableData="tableData" @getSlipData="fetchSlipData"/>
                <!-- pagination  -->
                    <van-pagination
                        v-model="currentPage"
                        :total-items="rows"
                        :items-per-page="10"
                        :show-page-size="3"
                        size="sm"
                        force-ellipses
                    >
                    <template #prev-text>
                        <van-icon name="arrow-left" />
                    </template>
                    <template #next-text>
                        <van-icon name="arrow" />
                    </template>
                    <template #page="{ text }">{{ text }}</template>
                    </van-pagination>
            </div>
        </div>
        <!-- === Voucher History === -->
            <div class="col-4">
                <div
                    class="modal fade"
                    id="voucher-history"
                    tabindex="-1"
                    role="dialog"
                    aria-labelledby="modal-form"
                    aria-hidden="true"
                >
                    <div
                        class="modal-dialog modal- modal-dialog-centered"
                        role="document"
                    >
                        <div class="modal-content">
                            <div
                                class="text-center modal-cross"
                                @click="closeModal"
                            >
                                X
                            </div>
                            <div
                                class="modal-body p-0"
                            >
                                <FBVoucher
                                    :type="type"
                                    :datas="datas"
                                    :slipId="slipId"
                                    :currentTime="bet_date"
                                    :isHistory="true"
                                    :is_result="is_result"
                                    :bingo_amount="bingo_amount"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    </div>
</template>
<script>
// import SideNav from './components/SideNav.vue';
import { DateTime } from 'luxon';
import { Loading } from 'vant';
import DataTable from './components/DataTable.vue';
import FBVoucher from './components/FBVoucher.vue';
import { mapActions } from 'vuex';

export default {
  components: { DataTable, FBVoucher, Loading },
  data() {
    return {
      tableData: {
        headings:
                    this.$store.state.language === 'en'
                      ? ['Amount', 'Balance', 'Type', 'Status', 'Action By', 'Date']
                      : ['ငွေအမောင့်', 'လက်ကျန်ငွေ', 'အမျိုးအစား', 'အခြေအနေ', 'လုက်ဆောင်သူ', 'နေ့စွဲ'],
        datas: [],
      },
      rows: 1,
      currentPage: 1,
      depositActive: false,
      withdrawActive: false,
      transactionType: 'all',
      params: {
        draw: '',
        date: '',
      },
      dateOptions: [],
      type: 'maung',
      datas: [],
      slipId: null,
      bet_date: null,
      bingo_amount: null,
      is_result: false,
      isLoading: false,
    };
  },
  methods: {
    ...mapActions(['fetchUser']),
    setOneMonth() {
      for (let i = 0; i < 30; i += 1) {
        const d = new Date();
        d.setHours(23, 59, 59);
        d.setDate(d.getDate() - i);
        const newObject = {};
        newObject.label = moment(d).format('l');
        newObject.data = d;
        this.dateOptions.push(newObject);
      }
    },
    closeModal() {
      $('#voucher-history').modal('hide');
    },
    fetchSlipData(data) {
      this.type = data.type;
      this.datas = data.team_vouchers;
      this.bingo_amount = data.bingo_amount;
      this.slipId = data.voucher_id;
      this.is_result = data.isBet;
      this.bet_date = this.betTime(data.created_at);
      $('#voucher-history').modal('show');
    },
    betTime(time) {
      const newTime = new Date(time);
      const targetTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const myanmarDateTime = DateTime.fromJSDate(newTime);
      const targetDateTime = myanmarDateTime.setZone(targetTimeZone);
      const adjustedDrawDate = new Date(targetDateTime.toISO()).toLocaleString('en-US', { timeZone: targetTimeZone });
      return moment(adjustedDrawDate).format('lll');
    },
    async fetchHistory(type = 'all') {
      try {
        this.transactionType = type;
        // this.depositActive = true;
        // this.withdrawActive = false;
        this.isLoading = true;
        this.tableData.contents = [
          'amount',
          'current_amount',
          'type',
          'status',
          'by_user_name',
          'created_at',
        ];
        const res = await axios.post('/transactions/get_by_solo', {
        //   params: {
          page: this.currentPage,
          limit: 10,
          user_id: this.$store.state.authUser.id,
          drawDate: this.params.date,
          type,
        //   },
        }, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
            });
        this.tableData.datas = res.data.data;
        for (let index = 0; index < this.tableData.datas.length; index += 1) {
          this.tableData.datas[index].created_at = this.betTime(this.tableData.datas[index].created_at);
          this.tableData.datas[index].amount = Number(this.tableData.datas[index].amount).toLocaleString();
          this.tableData.datas[index].current_amount = Number(this.tableData.datas[index].current_amount).toLocaleString();
        }
        this.isLoading = false;
        this.rows = res.data.total;
      } catch (error) {
        console.log(error);
      }
    },
    // async fetchWithdrawHistory() {
    //   try {
    //     this.depositActive = false;
    //     this.withdrawActive = true;
    //     this.tableData.contents = ['date', 'type', 'amount', 'status'];
    //     const res = await axios.get('/withdraws', {
    //       params: {
    //         page: this.currentPage,
    //         limit: 1,
    //       },
    //     });
    //     this.tableData.datas = res.data.data;
    //     this.rows = res.data.total;
    //   } catch (error) {
    //     console.log(error);
    //   }
    // },
  },
  watch: {
    currentPage() {
      this.fetchHistory();
    },
  },
  async mounted() {
    const authUser = await this.fetchUser();
    if (!authUser) {
      this.$router.push('/auth/login');
    }
    this.params.date = moment(new Date()).format('l');
    this.fetchHistory();
    this.setOneMonth();
  },
};
</script>
<style scoped>
.betslip-table {
    background: white;
}
.modal-cross {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    padding-top: 3px;
    margin-left: auto;
    color: white;
    cursor: pointer;
    margin-right: -9px;
    margin-top: -9px;
    background: rgb(228, 87, 87);
}
@media (max-width: 768px) {
    .main-wrapper {
        padding: 7px !important;
    }
    .btn {
        height: 30px !important;
        padding-top: 0px !important;
        padding-bottom: 0px !important;
        padding-right: 6px !important;
        padding-left: 6px !important;
        margin-top: 3px !important;
        margin-bottom: 3px !important;
    }
}
</style>
